import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import Hhoutside from '../assets/images/hhoutside.jpg';
import Moultriehh from '../assets/images/moultriehh.jpg';
import Ashburnhh from '../assets/images/ashburnhh.jpg';
import Jacksonhh from '../assets/images/jacksonhh.jpg';
import Viennahh from '../assets/images/viennahh.jpg';
import Heflinhh from '../assets/images/heflinhh.jpg';
import P2040036 from '../assets/images/p2040036.jpg';
import P2040025 from '../assets/images/p2040025.jpg';
import Jacksonvillehh from '../assets/images/jacksonvillehh.jpg';
import Oxford2hh from '../assets/images/oxford2hh.jpg';
import Talladegahh from '../assets/images/talladegahh.jpg';
import Lincolnhh from '../assets/images/lincolnhh.jpg';
import Roanokehh from '../assets/images/roanokehh.jpg';
import Sylacaugahh from '../assets/images/sylacaugahh.jpg';

export default function CheckListPage() {
  return (
    <>
      <SEO title="Photo Checklist" />
      <div>
        <p>
          Well we are so glad you found your way to the Other Huddle Houses
          page. This is the page where we list all the other Huddle Houses we
          have visited across the South. While we haven't been to nearly all of
          the them, we are ever increasing visits and planning trips to foreign
          Huddle Houses. Feel free to e-mail us your pictures of the Huddle
          House near you.
        </p>
      </div>
      <ImageGrid>
        <div>
          <img src={Hhoutside} alt="The Oxford Huddle House" />
          <ImageCaption>Oxford Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Oxford2hh} alt="Oxford 2 Huddle House" />
          <ImageCaption>Oxford 2 Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Moultriehh} alt="The Moultrie Huddle House" />
          <ImageCaption>Moultrie Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Jacksonvillehh} alt="Jacksonville Huddle House" />
          <ImageCaption>Jacksonville Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Talladegahh} alt="Talladega Huddle House" />
          <ImageCaption>Talladega Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Lincolnhh} alt="Lincoln Huddle House" />
          <ImageCaption>Lincoln Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Roanokehh} alt="Roanoke Huddle House" />
          <ImageCaption>Roanoke Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Sylacaugahh} alt="Sylacauga Huddle House" />
          <ImageCaption>Sylacauga Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Ashburnhh} alt="The Ashburn Huddle House" />
          <ImageCaption>Ashburn Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Jacksonhh} alt="The Jackson Huddle House" />
          <ImageCaption>Jackson Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Viennahh} alt="The Vienna Huddle House" />
          <ImageCaption>Vienna Huddle House</ImageCaption>
        </div>
        <div>
          <img src={Heflinhh} alt="The Heflin Huddle House" />
          <ImageCaption>Heflin Huddle House</ImageCaption>
        </div>
        <div>
          <img src={P2040036} alt="Huddle House" />
          <ImageCaption>Huddle House</ImageCaption>
        </div>
        <div>
          <img src={P2040025} alt="Huddle House" />
          <ImageCaption>Huddle House</ImageCaption>
        </div>
      </ImageGrid>
    </>
  );
}
